import { useEffect } from 'react';
import qs from 'qs';

type useReplaceStateType = (
  initParams?: Record<string, any>
) => Record<string, (params: Record<string, any>) => string>;

export const useReplaceState: useReplaceStateType = initParams => {
  useEffect(() => {
    if (initParams) {
      replaceState(initParams);
    }
  }, []);

  const replaceState = (params = {}) => {
    const { href } = global.location;
    const path = href.split('?')[0];
    const queryString = qs.stringify({
      ...params,
    });
    global.history.replaceState(null, '', `${path}?${queryString}`);
    return global.location.href;
  };

  return { replaceState };
};
