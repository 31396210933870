import { useEffect } from 'react';
import _ from 'lodash';

export const useInnerHeight = () => {
  const resizeHandle = _.debounce(() => {
    const height = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${height}px`);
  }, 200);

  useEffect(() => {
    resizeHandle();
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    };
  }, []);
};
