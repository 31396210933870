import React, { useContext, useEffect, useState } from 'react';
import { useWheelEvent } from '@/hooks/useWheelEvent';
import { GlobalContext, PageType } from '@/pages';
import styles from './index.module.less';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import classNames from 'classnames';
import Drawer from 'components/Drawer';
import Honor from 'components/Honor';
import { t } from '@lingui/macro';
import Footer from 'components/Footer';
import { useIsInternational } from '@/hooks/useIsInternational';
import { request } from '@/libs';
import { GlobalType } from '@/types/global';
import Icon from 'components/Icon';

SwiperCore.use([Autoplay, EffectFade]);

type PositionType = {
  title: string;
  category: number | null;
  lang: GlobalType.LangType | 'ja';
};

const footerHeight = 68;

const AboutUs: React.FC<{
  isActive: boolean;
}> = ({ isActive }) => {
  const [isShowJoinUs, setIsShowJoinUs] = useState<boolean>(false);
  const { swiperInstance, activePage, setActivePage } =
    useContext(GlobalContext);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const swiperPicsGroup: number[] = [0, 1];
  const { lang, isLang, isInternational } = useIsInternational();
  const [activePosition, setActivePosition] = useState<PositionType | null>(
    null
  );
  const [positionList, setPositionList] = useState<
    {
      department: string;
      id: number;
      title: string;
    }[]
  >([]);

  const positionTypes: {
    zh: PositionType[];
    jp: PositionType[];
    en: PositionType[];
    kr: PositionType[];
  } = {
    zh: [
      { title: '技术类', category: null, lang: 'zh' },
      { title: '美术类', category: null, lang: 'zh' },
      { title: '策划类', category: null, lang: 'zh' },
      { title: '市场/职能类', category: null, lang: 'zh' },
    ],
    jp: [
      { title: 'イラストレーター', category: 1, lang: 'ja' },
      { title: '3Dデザイナー', category: 8, lang: 'ja' },
      { title: '総合職', category: 9, lang: 'ja' },
      { title: 'アニメーションデザイナー', category: 3, lang: 'ja' },
    ],
    en: [
      { title: 'Art', category: 1, lang: 'en' },
      { title: 'Design', category: 2, lang: 'en' },
      { title: 'Animation', category: 3, lang: 'en' },
      { title: '3D Art', category: 8, lang: 'en' },
    ],
    kr: [
      {
        title: '2D 그래픽',
        category: 1,
        lang: 'kr',
      },
      {
        title: '애니메이션',
        category: 3,
        lang: 'kr',
      },
      {
        title: '3D 그래픽',
        category: 8,
        lang: 'kr',
      },
    ],
  };

  const getPositionList = async ({ category, lang }: PositionType) => {
    try {
      const { res } = await request(
        `https://gwadm.manjuu.com/api/v1/recruitment`,
        {
          method: 'GET',
          data: {
            lang,
            category,
            limit: 100,
            page: 1,
          },
        }
      );
      setPositionList(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setIsShowJoinUs(activePage === PageType.JoinUs);
    setIsShowDetail(false);
    if (activePage !== PageType.JoinUs) {
      setActivePosition(null);
    }
  }, [activePage]);

  useEffect(() => {
    if (activePosition) {
      getPositionList(activePosition);
      swiperInstance?.mousewheel?.disable();
    } else {
      swiperInstance?.mousewheel?.enable();
    }
  }, [activePosition]);

  useWheelEvent({
    isActive,
    otherHooks: [isShowJoinUs, isShowDetail, activePage, activePosition],
    upCallback() {
      if (activePosition) {
        return;
      }
      if (!isShowDetail) {
        if (!isShowJoinUs) {
          swiperInstance?.slidePrev();
        } else {
          setActivePage?.(activePage - 1);
          setIsShowJoinUs(false);
        }
      }
    },
    downCallback() {
      if (isInternational && isShowJoinUs) {
        return;
      }
      if (isLang('zh-tw') && PageType.AboutUs === activePage) {
        return;
      }
      if (!isShowDetail) {
        if (isShowJoinUs) {
          swiperInstance?.slideNext();
        } else {
          setIsShowJoinUs(true);
        }
        setActivePage?.(activePage + 1);
      }
    },
  });

  const SwiperConfig: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    loopedSlides: 1,
    simulateTouch: false,
    slidesPerView: 1,
    effect: 'fade',
  };

  const clickShowDetail = () => {
    setIsShowDetail(!isShowDetail);
    if (swiperInstance?.mousewheel) {
      swiperInstance.mousewheel.disable();
    }
  };

  const clickPosition = (p?: PositionType) => {
    if (isLang('zh')) {
      window.open('https://app.mokahr.com/apply/manjiu/40770#/');
      return;
    }
    if (p) {
      setActivePosition(p);
    }
  };

  const getPicUrl = (index: number) => {
    return require(`@/public/images/cat/cat_${index}.jpg`);
  };

  const closeShowPosition = () => {
    setActivePosition(null);
  };

  return (
    <div className={styles.mj_aboutUs_container}>
      <Swiper
        style={{
          marginBottom: isInternational ? '4px' : 0,
          height: `calc(var(--vh) - ${
            isShowJoinUs || PageType.JoinUs <= activePage
              ? isInternational
                ? 300 + footerHeight
                : 300
              : isLang('zh-tw')
              ? 156
              : 88
          }px)`,
        }}
        className={classNames([
          styles.swiperWrapper,
          isShowJoinUs && activePosition && styles.swiperHide,
        ])}
        {...SwiperConfig}>
        {swiperPicsGroup.map((p, i) => (
          <SwiperSlide key={i}>
            <div className={classNames([styles.mj_aboutUs_loop_pic])}>
              <Image
                fill
                priority
                src={getPicUrl(p)}
                alt="Manjuu Pic"
                crossOrigin="anonymous"
                className={styles.mj_aboutUs_loop_picSelf}
              />
            </div>
          </SwiperSlide>
        ))}
        <div
          className={classNames([
            styles.mj_aboutUs_content,
            isShowDetail && styles.mj_aboutUs_content_show,
          ])}>
          <div className={styles.mj_aboutUs_content_title}>
            <h1
              className={styles.mj_aboutUs_content_mainTitle}>{t`蛮啾网络`}</h1>
            {!isLang('en') && (
              <div className={styles.mj_aboutUs_content_subTitle}>
                {isLang('kr') ? 'Manjuu Korea' : 'Manjuu Games'}
              </div>
            )}
          </div>
          <div
            lang={lang == 'zh-tw' ? 'zh' : lang}
            className={styles.mj_aboutUs_content_detail}>
            {t`上海蛮啾网络科技有限公司（Manjuu Co., Ltd.）于2015年01月成立。因为LOGO标志性的形象“啾”和主题色，“蛮啾”也被玩家亲切地称之为“小黄鸡”。`}
            <br />
            <br />
            {t`目前，蛮啾主要作品为二次元战舰策略手游《碧蓝航线》，游戏相关产品已登陆海内外市场并取得优秀的成绩。同时，蛮啾一直致力于原创游戏的不断创新，更在原创IP、漫画、动画、周边等更多的领域上不断探索和努力，并有自研产品正在锐意开发中。`}
          </div>

          <div
            onClick={clickShowDetail}
            className={classNames([
              styles.aboutUs_link,
              styles.aboutUs_link_btn,
              isShowDetail && styles.aboutUs_link_active,
              activePage >= PageType.JoinUs && styles.aboutUs_link_hidden,
            ])}>
            <div className={styles.aboutUs_link_content}>
              <div
                className={classNames([
                  styles.aboutUs_link_dot,
                  styles.aboutUs_link_dot_left,
                ])}
              />
              {t`荣誉资质`}
              <div
                className={classNames([
                  styles.aboutUs_link_dot,
                  styles.aboutUs_link_dot_right,
                ])}></div>
              <div className={styles.aboutUs_link_subtitle}>MANJUU HONORS</div>
              <Icon
                className={styles.aboutUs_link_right_arrow}
                color="#fff"
                fontClass="ICON-28"
              />
            </div>
          </div>
        </div>
      </Swiper>

      {!isLang('zh-tw') && (
        <div
          className={classNames([
            styles.mj_joinUs_container,
            activePosition && styles.mj_joinUs_container_show,
          ])}>
          {isInternational && (
            <div className={styles.mj_joinUs_international_title}>
              <div className={styles.mj_joinUs_international_dot}></div>JOIN US{' '}
              <div className={styles.mj_joinUs_international_dot}></div>
            </div>
          )}
          <div className={styles.mj_joinUs_position_body}>
            {lang !== 'zh-tw' &&
              positionTypes[lang].map((p, i) => (
                <div
                  onClick={() => {
                    clickPosition(p);
                  }}
                  key={i}
                  className={classNames([
                    styles.mj_joinUs_position_item,
                    activePosition?.category === p.category &&
                      styles.mj_joinUs_position_item_active,
                  ])}>
                  <div className={styles.mj_joinUs_position_name}>
                    <div className={styles.mj_joinUs_position_name_dot} />
                    <div className={styles.mj_joinUs_position_name_text}>
                      {p.title}
                    </div>
                  </div>
                  <div className={styles.mj_joinUs_position_subName}>
                    RECRUIT
                    <div>
                      <Icon
                        className={classNames([
                          styles.mj_joinUs_position_right_arrow,
                          p.category === activePosition?.category &&
                            styles.mj_joinUs_position_right_arrow_active,
                        ])}
                        fontClass="jiantou-41"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {isInternational && (
            <div
              className={classNames([
                styles.mj_joinUs_global_position_body,
                activePosition && styles.mj_joinUs_global_position_body_show,
              ])}>
              <div className={styles.mj_joinUs_global_position}>
                <div className={styles.mj_joinUs_global_position_title}>
                  <div
                    className={
                      styles.mj_joinUs_global_position_title_item
                    }>{t`职位`}</div>
                  <div
                    className={
                      styles.mj_joinUs_global_position_title_item
                    }>{t`所属部门`}</div>
                </div>
                <div className={styles.mj_joinUs_global_position_list}>
                  {positionList.map(p => (
                    <a
                      rel="noopener noreferrer"
                      href={`/${lang}/position/${p.id}`}
                      target={'_blank'}
                      key={p.id}
                      className={styles.mj_joinUs_global_position_list_item}>
                      <div>{p.title}</div>
                      <div>{p.department}</div>
                    </a>
                  ))}
                </div>
              </div>

              <div
                onClick={closeShowPosition}
                className={styles.mj_joinUs_global_position_closeBtn}>
                BACK
              </div>
            </div>
          )}

          {isLang('zh') && (
            <div
              onClick={() => {
                clickPosition();
              }}
              className={styles.mj_joinUs_moka}>
              前往投递
            </div>
          )}

          <div className={styles.mj_joinUs_bottom_text}>
            WELCOME TO JOIN MANJUU
          </div>
        </div>
      )}

      <Drawer
        width={688}
        isShowMask
        clickMaskClosable
        visible={isShowDetail}
        onClose={ev => {
          setIsShowDetail(ev);
          if (swiperInstance?.mousewheel) {
            swiperInstance.mousewheel.enable();
          }
        }}>
        <Honor />
      </Drawer>
      {isInternational && <Footer isShowFooter={false} />}
    </div>
  );
};

export default AboutUs;
