import { useContext, useEffect } from 'react';
import { GlobalContext } from '@/pages';

type CallbackType = () => void;

export const useWheelEvent = ({
  isActive,
  upCallback,
  downCallback,
  otherHooks,
}: {
  isActive: boolean;
  upCallback?: CallbackType;
  downCallback?: CallbackType;
  otherHooks?: any[];
}) => {
  let timeId: NodeJS.Timeout | null = null;
  const { swiperInstance } = useContext(GlobalContext);

  useEffect(() => {
    if (swiperInstance && isActive) {
      document.body.addEventListener('wheel', wheelListener);
    }
    if (!isActive) {
      document.body.removeEventListener('wheel', wheelListener);
    }
    return () => {
      document.body.removeEventListener('wheel', wheelListener);
    };
  }, [swiperInstance, isActive, ...(otherHooks ? otherHooks : [])]);

  const wheelListener = (event: WheelEvent) => {
    if (timeId) {
      clearTimeout(timeId);
    }
    timeId = setTimeout(() => {
      if (event?.deltaY < 0) {
        upCallback?.();
      } else if (event?.deltaY > 0) {
        downCallback?.();
      }
    }, 75);
  };
};
