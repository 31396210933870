import React, { useContext, useEffect, useState } from 'react';
import { useWheelEvent } from '@/hooks/useWheelEvent';
import styles from './index.module.less';
import { GlobalContext, PageType } from '@/pages';
import classNames from 'classnames';

const HomePage: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { setActivePage } = useContext(GlobalContext);
  const [isShow, setIsShow] = useState<boolean>(true);

  useWheelEvent({
    isActive,
    downCallback() {
      clickNextPage();
    },
  });

  useEffect(() => {
    setIsShow(isActive);
  }, [isActive]);

  const clickNextPage = () => {
    setActivePage?.(PageType.Products);
  };

  return (
    <div
      className={classNames([
        styles.mj_homePage_container,
        !isShow && styles.mj_homePage_container_hide,
      ])}>
      <div className={styles.mj_homePage_video_body}>
        <video
          poster="/images/homepage-poster.png"
          className={styles.mj_homePage_video}
          muted
          preload="auto"
          autoPlay
          loop>
          <source src={`/video/homepage.mp4`} />
        </video>
      </div>
    </div>
  );
};

export default HomePage;
