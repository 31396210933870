import React, {
  useState,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
} from 'react';
import { useWheelEvent } from '@/hooks/useWheelEvent';
import { GlobalContext } from '@/pages';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import styles from './index.module.less';
import classNames from 'classnames';
import Image from 'next/image';
import { useIsInternational } from '@/hooks/useIsInternational';
import Footer from 'components/Footer';
import Icon from 'components/Icon';

const News: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { swiperInstance, setActivePage } = useContext(GlobalContext);
  const [isShowFooter, setIsShowFooter] = useState<boolean>(false);
  const [newsList] = useState<{
    zh: {
      year: string | number;
      title: string | ReactNode;
      desc: string;
      picUrl: string;
      newsUrl: string;
    }[];
    jp: any[];
    en: any[];
    ['zh-tw']: any[];
    kr: any[];
  }>({
    zh: [
      {
        year: 2021,
        title: (
          <>
            慈有爱，善无界，
            <br />
            一方有难八方支援
          </>
        ),
        desc: '上海蛮啾网络科技有限公司欲为河南灾民捐赠一批饮用水，该公司代表杜智文女士第一时间联系到百年老品牌饮用水公司......',
        picUrl: require('@/public/images/news/news_0.png'),
        newsUrl:
          'https://www.brightmeat.com/news/pro_1.html?context=1128&type=2',
      },
      {
        year: 2022,
        title: <>携手上海血液中心《碧蓝航线》无偿献血公益合作</>,
        desc: '无偿献血，让生命再度启航！「碧蓝航线」x「上海市血液中心」全新合作公益活动于12月29日正式开启！《碧蓝航线》愿与所有指挥官携手贡献一份力量......',
        picUrl: require('@/public/images/news/news_1.jpg'),
        newsUrl: 'https://www.tgbus.com/news/198506',
      },
      {
        year: 2020,
        title: (
          <>
            《碧蓝航线》×川剧变脸，
            <br />
            演绎千古人间事
          </>
        ),
        desc: '《碧蓝航线》×文化传承系列第二部作品——「碧蓝航线×川剧变脸」，与指挥官们见面啦！“唐三千，宋八百，数不尽的汉三国。”川剧演绎着千古人间事......',
        picUrl: require('@/public/images/news/news_2.png'),
        newsUrl:
          'https://www.bilibili.com/video/BV1i7411B7sT/?vd_source=513def16383824a956e299b908c25011',
      },
      {
        year: 2021,
        title: (
          <>
            《碧蓝航线》×WWF，
            <br />
            最可爱的伙伴！
          </>
        ),
        desc: '青山绿水间，守护万千生灵。向公益进发，我们一直在行动！《碧蓝航线》将再次携手@WWF世界自然基金会 、@一个地球OPF ，一同探访大熊猫栖息地......',
        picUrl: require('@/public/images/news/news_3.png'),
        newsUrl:
          'https://www.bilibili.com/video/av972028736/?vd_source=513def16383824a956e299b908c25011',
      },
      {
        year: 2021,
        title: <>《碧蓝航线》动物保护公益活动</>,
        desc: '早在两年前，《碧蓝航线》就开始了动物保护相关的公益活动。有趣的是，第一次公益保护的动物就是海豹。在《碧蓝航线》两周年庆典的时候，B站宣布......',
        picUrl: require('@/public/images/news/news_4.png'),
        newsUrl:
          'https://baijiahao.baidu.com/s?id=1694125221025054732&wfr=spider&for=pc',
      },
      {
        year: 2017,
        title: <>《碧蓝航线》爱眼公益活动</>,
        desc: '《碧蓝航线》携手德高巴士、奥比斯中国，在线下共同举行爱眼公益活动!',
        picUrl: require('@/public/images/news/news_5.png'),
        newsUrl: 'http://www.gamedog.cn/news/20171104/2249574.html',
      },
    ],
    jp: [],
    ['zh-tw']: [],
    kr: [],
    en: [],
  });

  const { lang } = useIsInternational();

  useEffect(() => {
    setIsShowFooter(false);
  }, [isActive]);

  const openNewsLink = (url: string | URL | undefined) => {
    if (url) {
      window.open(url);
    }
  };

  useWheelEvent({
    isActive,
    otherHooks: [isShowFooter],
    upCallback() {
      if (isShowFooter) {
        setIsShowFooter(!isShowFooter);
        return;
      }
      if (setActivePage) {
        setActivePage(3);
      }
      swiperInstance?.slidePrev();
    },
    downCallback() {
      setIsShowFooter(true);
    },
  });

  const SwiperConfig: SwiperProps = {
    spaceBetween: 4,
    loopedSlides: 1,
    slidesPerView: 5.2,
  };

  const sortByDesc = useMemo(() => {
    return newsList[lang].sort((a, b) => b.year - a.year);
  }, [newsList, lang]);

  return (
    <div className={styles.mj_news_container}>
      <div
        className={classNames([
          styles.mj_news_list,
          isShowFooter && styles.mj_show_footer,
        ])}>
        <Swiper
          resistanceRatio={0}
          {...SwiperConfig}
          className={styles.swiper_container}>
          {sortByDesc.map(({ year, title, desc, picUrl, newsUrl }, i) => (
            <SwiperSlide key={i}>
              <div className={styles.mj_news_item}>
                <div
                  onClick={() => {
                    openNewsLink(newsUrl);
                  }}
                  className={styles.mj_news_pic}>
                  <Image
                    fill
                    priority
                    alt="Manjuu Pic"
                    crossOrigin="anonymous"
                    style={{ objectFit: 'cover' }}
                    src={picUrl}
                  />
                </div>
                <div className={styles.mj_news_detail}>
                  <div className={styles.mj_news_time}>
                    <div>YEAR</div>
                    <div>{year}</div>
                  </div>
                  <div className={styles.mj_news_title}>{title}</div>
                  <div className={styles.mj_news_desc}>{desc}</div>
                </div>
                <div
                  onClick={() => {
                    openNewsLink(newsUrl);
                  }}
                  className={styles.mj_news_linkBtn}>
                  <div>DETAILS</div>
                  <div>查看详情</div>

                  <div className={styles.mj_news_linkBtn_rightBtn}>
                    <Icon
                      className={styles.mj_news_linkBtn_rightBtn_icon}
                      fontClass="jiantou-41"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Footer setIsShowFooter={setIsShowFooter} isShowFooter={isShowFooter} />
    </div>
  );
};

export default News;
