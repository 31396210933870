import React, { useContext } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { ProductsTypes } from 'components/Products';
import Icon from 'components/Icon';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { useIsInternational } from '@/hooks/useIsInternational';
import { bgc_img } from '@/pages';
import { DrawerContext } from 'components/Drawer';

export const ManjuuQRCodePath = require('@/public/images/QRCode.jpeg');
export const AZURLANEQRCode = require('@/public/images/AZURLANEQRCode2.jpeg');

const ProductsDetails: React.FC<{
  activeProductsType: ProductsTypes;
  setActiveProductsType: React.Dispatch<ProductsTypes>;
}> = ({ activeProductsType, setActiveProductsType }) => {
  const { lang, isLang } = useIsInternational();
  const { visible } = useContext(DrawerContext);
  const clickLinkOpenNewPage = (url: string) => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <div
      className={classNames([
        styles.productsDetails_container,
        visible && styles.productsDetails_container_show,
      ])}>
      <div className={styles.productsDetails_flex}>
        {' '}
        <Image
          className={styles.mj_container_bgc}
          fill
          src={bgc_img}
          alt="pic"
        />
        <div
          className={classNames([
            styles.productsDetails_title_body,
            (isLang('kr') || isLang('jp')) &&
              styles.productsDetails_title_body_krOjp,
            isLang('en') && styles.productsDetails_title_body_en,
          ])}>
          <div className={styles.productsDetails_title_name}>
            {t`蓝色星原：旅谣`}
          </div>
          {!isLang('en') && (
            <div
              className={classNames([
                styles.productsDetails_title_subTitle,
                (isLang('kr') || isLang('jp')) &&
                  styles.productsDetails_title_subTitle_krOjp,
              ])}>
              / AZUR PROMILIA
            </div>
          )}
        </div>
        <div className={styles.productsDetails_introduce}>
          <div
            className={
              styles.productsDetails_introduce_title
            }>{t`产品简介`}</div>
          {!isLang('en') && !isLang('kr') && (
            <div className={styles.productsDetails_introduce_subtitle}>
              INTRODUCTION
            </div>
          )}
        </div>
        <div
          lang={lang == 'zh-tw' ? 'zh' : lang}
          className={styles.productsDetails_detail}
          dangerouslySetInnerHTML={{
            __html: t`《蓝色星原：旅谣》是由蛮啾网络推出的一款全新星宠结伴 幻想大世界RPG。向往星原的奇幻旅途，将与你再次重逢。<br/><br/>星临者，欢迎回到普罗米利亚！`,
          }}
        />
        <div className={styles.productsDetails_link_body}>
          <div
            onClick={() => {
              const location = `https://azurpromilia${
                isLang('zh') ? '.manjuu' : ''
              }.com/${isLang('zh') ? 'home' : `${lang}/home`}/`;

              clickLinkOpenNewPage(location);
            }}
            className={classNames([
              styles.productsDetails_link_website,
              styles.productsDetails_link_btn,
            ])}>
            <div
              className={classNames([
                styles.productsDetails_link_website_dot,
                styles.productsDetails_link_website_dot_left,
              ])}
            />
            {t`前往官网`}
            <div
              className={classNames([
                styles.productsDetails_link_website_dot,
                styles.productsDetails_link_website_dot_right,
              ])}
            />
          </div>

          {isLang('zh') && (
            <>
              <div
                onClick={() => {
                  clickLinkOpenNewPage(
                    'https://space.bilibili.com/3546569016085336'
                  );
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_bilibili,
                ])}>
                <Icon fontClass="ICON-35" />
              </div>
              <div
                onClick={() => {
                  clickLinkOpenNewPage('https://weibo.com/u/7832445927');
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_weibo,
                ])}>
                <Icon fontClass="ICON-23" />
              </div>

              <div
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_wechat,
                ])}>
                <div className={styles.productsDetails_link_wechat_QRCode}>
                  <Image
                    className={styles.productsDetails_link_wechat_QRCodeImage}
                    width={136}
                    height={136}
                    src={AZURLANEQRCode}
                    alt="QRCode"
                  />
                </div>
                <Icon fontClass="ICON-33" />
              </div>
              <div
                onClick={() => {
                  clickLinkOpenNewPage('https://www.taptap.cn/app/593829');
                }}
                className={classNames([
                  styles.productsDetails_link_btn,
                  styles.productsDetails_link_bilibili,
                ])}>
                <Icon fontClass="ICON-2-27" />
              </div>
            </>
          )}
          {/* {isInternational && (
            <>
              {!isLang('zh') && (
                <>
                  <div
                    onClick={() => {
                      const localeWebsite = {
                        zh: '',
                        ['zh-tw']: 'https://www.facebook.com/AzurStellarTW',
                        jp: 'https://www.facebook.com/AzurPromiliaJP',
                        kr: 'https://www.facebook.com/AzurPromiliaKR',
                        en: 'https://www.facebook.com/AzurPromiliaEN',
                      };
                      clickLinkOpenNewPage(localeWebsite[lang]);
                    }}
                    className={classNames([
                      styles.productsDetails_link_btn,
                      styles.productsDetails_link_face,
                    ])}>
                    <Icon fontClass="ICON-38" />
                  </div>

                  <div
                    onClick={() => {
                      const localeWebsite = {
                        zh: '',
                        ['zh-tw']: 'https://www.youtube.com/@AzurStellar_TW',
                        jp: 'https://www.youtube.com/@AzurPromilia_JP',
                        kr: 'https://www.youtube.com/@AzurPromilia_KR',
                        en: 'https://www.youtube.com/@AzurPromilia_EN',
                      };
                      clickLinkOpenNewPage(localeWebsite[lang]);
                    }}
                    className={classNames([
                      styles.productsDetails_link_btn,
                      styles.productsDetails_link_youtube,
                    ])}>
                    <Icon fontClass="ICON-36" />
                  </div>
                  <div
                    onClick={() => {
                      const localeWebsite = {
                        zh: '',
                        ['zh-tw']: 'https://twitter.com/AzurStellar_TW',
                        jp: 'https://twitter.com/AzurPromilia_JP',
                        kr: 'https://twitter.com/AzurPromilia_KR',
                        en: 'https://twitter.com/AzurPromilia_EN',
                      };
                      clickLinkOpenNewPage(localeWebsite[lang]);
                    }}
                    className={classNames([
                      styles.productsDetails_link_btn,
                      styles.productsDetails_link_twitter,
                    ])}>
                    <Icon fontClass="ICON-2-25" />
                  </div>
                </>
              )}
              {isLang('en') && (
                <div
                  onClick={() => {
                    clickLinkOpenNewPage(
                      'https://www.reddit.com/r/AzurPromilia/'
                    );
                  }}
                  className={classNames([
                    styles.productsDetails_link_btn,
                    styles.productsDetails_link_azur,
                  ])}>
                  <Icon fontClass="ICON-2-26" />
                </div>
              )}
              {isLang('jp') && (
                <div
                  onClick={() => {
                    clickLinkOpenNewPage(
                      'https://www.instagram.com/AzurPromilia_JP'
                    );
                  }}
                  className={classNames([
                    styles.productsDetails_link_btn,
                    styles.productsDetails_link_ins,
                  ])}>
                  <Icon fontClass="ICON-2-24" />
                </div>
              )}
              {isLang('en') && (
                <div
                  onClick={() => {
                    clickLinkOpenNewPage(
                      'https://www.instagram.com/AzurPromilia_EN'
                    );
                  }}
                  className={classNames([
                    styles.productsDetails_link_btn,
                    styles.productsDetails_link_ins,
                  ])}>
                  <Icon fontClass="ICON-2-24" />
                </div>
              )}
            </>
          )} */}
        </div>
        <div className={styles.productsDetails_switchProducts_button_body}>
          <div
            onClick={() => {
              if (activeProductsType === 'p1') {
                setActiveProductsType('p3');
              }
            }}
            className={styles.productsDetails_switchProducts_button}></div>
          <div
            onClick={() => {
              if (activeProductsType === 'p1') {
                setActiveProductsType('p2');
              }
            }}
            className={styles.productsDetails_switchProducts_button}></div>
        </div>
      </div>
    </div>
  );
};

export default ProductsDetails;
