import React, { useState } from 'react';
import styles from './index.module.less';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useIsInternational } from '@/hooks/useIsInternational';
import SwiperCore, { FreeMode, Mousewheel } from 'swiper';
import Image from 'next/image';
import { bgc_img } from '@/pages';
import { t } from '@lingui/macro';

type honorListTypes = {
  year: string | number;
  title: string;
  descList: string[];
};

SwiperCore.use([FreeMode, Mousewheel]);

const Honor: React.FC = () => {
  const { lang } = useIsInternational();
  const [honorList] = useState<{
    zh: honorListTypes[];
    kr: honorListTypes[];
    'zh-tw': honorListTypes[];
    jp: honorListTypes[];
    en: honorListTypes[];
  }>({
    zh: [
      {
        year: 2022,
        title: `「${t`碧蓝航线`}」`,
        descList: ['金茶奖-年度最佳二次元游戏', 'GTA-最佳长线运营游戏'],
      },
      {
        year: 2021,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '中国游戏十强大奖-十大最受海外欢迎游戏',
          '哔哩哔哩游戏大赏-最佳持续运营手游',
        ],
      },
      {
        year: 2020,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'Google Play-最具创新力游戏',
          '金翎奖-最佳二次元移动游戏',
          'Sensor Tower中国出海手游收入排行榜TOP30',
        ],
      },
      {
        year: 2019,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '首个TV动画化的中国国产手游IP',
          'Sensor Tower中国出海手游收入排行榜TOP30',
        ],
      },
      {
        year: 2018,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '多地区畅销榜登顶',
          '入选Google Play2017年最佳游戏用户票选奖前五名',
          '首批登录PS4平台的中国国产手游IP之一',
          'Sensor Tower中国出海手游收入排行榜TOP30',
          '中国动漫金龙奖-游戏奖铜奖',
        ],
      },
    ],
    jp: [
      {
        year: 2022,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'Golden Plume Award - Best Mobile Game',
          '中国金茶賞2022年ベストACGゲーム',
          'Best Ongoing Game – GTA2022',
        ],
      },
      {
        year: 2021,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'China Games Award - 海外で最も人気なゲーム',
          'bilibili Games Award – Best Ongoing Game',
        ],
      },
      {
        year: 2020,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '中国第15回金竜賞 最優秀ゲーム部門 銅賞',
          'Google Play-ベストゲームノミネート',
        ],
      },
      {
        year: 2019,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'テレビアニメ「アズールレーン」公開',
          '中国製スマホゲームにおいて初のTVアニメ化',
        ],
      },
      {
        year: 2018,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '複数の国・地域でトップセールスを達成',
          'Google Play 2017年ベストゲームノミネート',
          'PS4向けゲーム「アズールレーンクロスウェーブ」発表',
          '中国製スマホゲームにおいていち早くPS4コンシューマゲーム化を達成',
        ],
      },
    ],
    kr: [
      {
        year: 2022,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'The Golden Tea Award 2022년 베스트 서브컬처 게임 선정',
          'GTA 베스트 장선 운영 게임 선정',
        ],
      },
      {
        year: 2021,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '중국 최강 10대상 해외 베스트 인기 게임 부문 수상',
          'bilibili Game Award 베스트 운영 게임 선정',
        ],
      },
      {
        year: 2020,
        title: `「${t`碧蓝航线`}」`,
        descList: ['Google Play 베스트 창의적인 게임 선정'],
      },
      {
        year: 2019,
        title: `「${t`碧蓝航线`}」`,
        descList: ['애니메이션 출시'],
      },
      {
        year: 2018,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '중국/일본 App Store 베스트 셀러',
          '한국 안드로이드 무료 게임 순위 1위',
          '한국 Google Play 매출 순위 Top10',
          '2017년 Google Play 베스트 게임 Top5',
          'China Animation & Comic Competition Golden Dragon Award 게임 부문 동상 수상',
        ],
      },
    ],
    ['zh-tw']: [
      {
        year: 2022,
        title: `「${t`碧蓝航线`}」`,
        descList: ['金茶獎-年度最佳二次元遊戲', 'GTA-最佳長線運營遊戲'],
      },
      {
        year: 2021,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '中國遊戲十強大獎-十大最受海外歡迎遊戲',
          'bilibili遊戲大賞-最佳持續運營手遊',
        ],
      },
      {
        year: 2020,
        title: `「${t`碧蓝航线`}」`,
        descList: ['Google Play-最具創新力遊戲', '金翎獎-最佳二次元移動遊戲'],
      },
      {
        year: 2019,
        title: `「${t`碧蓝航线`}」`,
        descList: ['首個TV動畫化的中國國產手遊IP'],
      },
      {
        year: 2018,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          '多地區暢銷榜登頂',
          '入選Google Play 2017年最佳遊戲用戶票選獎前五名',
          '首批登陸PS4平台的中國國產手遊IP之一',
          '中國動漫金龍獎-遊戲獎銅獎',
        ],
      },
    ],
    en: [
      {
        year: 2022,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'The Golden Tea Award: The Best ACG Game ',
          'GTA: The Best Ongoing Game',
        ],
      },
      {
        year: 2021,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'The "Top 10" Game Award by CADPA: The 10 Most Popular Games in Overseas Markets',
          'The Bilibili Game Awards: The Best Ongoing Mobile Game',
        ],
      },
      {
        year: 2020,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'Google Play: Most Innovative Game',
          'The Golden Plume Award: Best ACG Mobile Game',
        ],
      },
      {
        year: 2019,
        title: `「${t`碧蓝航线`}」`,
        descList: ['The first CN mobile game IP to release an anime series'],
      },
      {
        year: 2018,
        title: `「${t`碧蓝航线`}」`,
        descList: [
          'IOS Best Selling Game in multiple countries and regions',
          'Google Play Best Game Award: Top 5',
          'Golden Dragon Award by CACC (China Animation & Comic Competition): Bronze Medal',
        ],
      },
    ],
  });

  const SwiperConfig: SwiperProps = {
    slidesPerView: 'auto',
    freeMode: true,
    direction: 'vertical',
    mousewheel: true,
  };

  return (
    <div className={styles.mj_honor_container}>
      <Image className={styles.mj_container_bgc} fill src={bgc_img} alt="pic" />
      <Swiper {...SwiperConfig} className={styles.mj_honor_list}>
        {honorList[lang]?.map((h, i) => (
          <SwiperSlide key={h.year} className={styles.mj_honor_list_item}>
            <div className={styles.mj_honor_list_item_detail_container}>
              {h.descList.map((d, ic) => (
                <React.Fragment key={ic}>
                  {ic === 0 && (
                    <div className={styles.mj_honor_list_item_title}>
                      <div className={styles.mj_honor_list_item_title_subTitle}>
                        M A N J U U
                      </div>
                      <div className={styles.mj_honor_list_item_title_text}>
                        {h.title}
                      </div>
                    </div>
                  )}
                  <div key={ic} className={styles.mj_honor_list_item_detail}>
                    <div className={styles.mj_honor_list_item_detail_bgcYear}>
                      {h.year}
                    </div>
                    <div className={styles.mj_honor_list_item_detail_text}>
                      {d}
                    </div>
                  </div>
                  {ic === h.descList.length - 1 &&
                    honorList[lang].length - 1 !== i && (
                      <div
                        className={styles.mj_honor_list_item_detail_decoration}
                      />
                    )}
                </React.Fragment>
              ))}
            </div>

            <div className={styles.mj_honor_list_item_year}>
              <div className={styles.mj_honor_list_item_yearTitle}>YEAR</div>
              <div className={styles.mj_honor_list_item_yearNum}>{h.year}</div>
            </div>
            {i === honorList[lang].length - 1 && (
              <div className={styles.mj_honor_bottom_bar}>- Manjuu Games -</div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Honor;
