import React, { useState } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import Image from 'next/image';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';
import Drawer from 'components/Drawer';
import ContactUs from 'components/ContactUs';

const LogoPath = require('@/public/images/Logo-white.png');
const record = require('@/public/images/recordation.png');

const Footer: React.FC<{
  isShowFooter: boolean;
  setIsShowFooter?: React.Dispatch<boolean>;
}> = ({ isShowFooter, setIsShowFooter }) => {
  const { lang, isLang, isInternational } = useIsInternational();
  const [isShowContactUsDrawer, setIsShowContactUsDrawer] =
    useState<boolean>(false);

  return (
    <>
      <div
        style={{
          height: isLang('zh') ? '194px' : '68px',
        }}
        className={classNames([
          styles.mj_footer_container,
          isShowFooter && styles.mj_show_footer,
        ])}>
        {isInternational && (
          <div className={styles.mj_footer_global_container}>
            <div className={styles.mj_footer_global_protocol}>
              <a
                target="_blank"
                className={styles.protocol_link}
                href={`/${lang}/protocol/privacy`}
                rel="noreferrer">{t`隐私政策`}</a>
              <div></div>
              <a
                target="_blank"
                className={styles.protocol_link}
                href={`/${lang}/protocol/userAgreement`}
                rel="noreferrer">{t`用户协议`}</a>
              {isLang('zh') && (
                <a
                  target="_blank"
                  className={styles.protocol_link}
                  href={`/${lang}/protocol/childProtection`}
                  rel="noreferrer">{t`儿童隐私保护规则及监护人须知`}</a>
              )}
            </div>
            <div className={styles.mj_footer_global_bottom_text}>
              {isLang('kr')
                ? '© 만쥬코리아 유한회사 MANJUU KOREA Inc. All rights reserved.'
                : '©Manjuu Co.,Ltd.'}
            </div>
          </div>
        )}

        {isLang('zh') && (
          <>
            <div
              onClick={() => {
                if (setIsShowFooter) {
                  setIsShowFooter(!isShowFooter);
                }
              }}
              className={styles.mj_footer_title_bar}>
              版权信息
            </div>
            <div className={styles.mj_footer_content}>
              {/* <div className={styles.mj_footer_content_left}>
              <div className={styles.mj_footer_content_left_contactUs_title}>
                联系我们
              </div>
              <div className={styles.mj_footer_content_left_contact_way}>
                <div>邮箱：cs@manjuu.com</div>
                <div>电话：021-5436 2673</div>
              </div>
              <div className={styles.mj_footer_content_left_contact_link}>
                <div
                  onClick={() => {
                    openSocialLink('https://weibo.com/6124450240');
                  }}
                  className={styles.mj_footer_content_left_contact_linBtn}>
                  <Icon fontClass="ICON-34" />
                </div>
                <div
                  onClick={() => {
                    openSocialLink('https://space.bilibili.com/626332915');
                  }}
                  className={styles.mj_footer_content_left_contact_linBtn}>
                  <Icon fontClass="ICON-35" />
                </div>
                <div
                  className={classNames([
                    styles.mj_footer_content_left_contact_linBtn,
                    styles.mj_footer_content_left_contact_linBtn_wechat,
                  ])}>
                  <div className={styles.mj_footer_content_left_wechat_QRCode}>
                    <Image
                      className={
                        styles.mj_footer_content_left_wechat_QRCodeImage
                      }
                      width={136}
                      height={136}
                      src={ManjuuQRCodePath}
                      alt="QRCode"></Image>
                  </div>
                  <Icon fontClass="ICON-33" />
                </div>
              </div>
            </div> */}
              {/* <div className={styles.mj_footer_content_left_border} /> */}
              <div className={styles.mj_footer_content_right}>
                <div className={styles.mj_footer_content_right_Logo}>
                  <Image
                    width={200}
                    height={70}
                    alt="Manjuu"
                    unoptimized
                    src={LogoPath}
                  />
                </div>
                <div className={styles.mj_footer_content_right_content}>
                  <div className={styles.mj_footer_content_right_protocol}>
                    <a
                      target="_blank"
                      className={styles.protocol_link}
                      href={`/${lang}/protocol/userAgreement`}
                      rel="noreferrer">
                      用户协议
                    </a>
                    <a
                      target="_blank"
                      className={styles.protocol_link}
                      href={`/${lang}/protocol/privacy`}
                      rel="noreferrer">
                      隐私政策
                    </a>
                    <a
                      target="_blank"
                      className={styles.protocol_link}
                      href={`/${lang}/protocol/childProtection`}
                      rel="noreferrer">
                      儿童隐私保护规则及监护人须知
                    </a>
                    <a
                      href="#"
                      className={styles.protocol_link}
                      rel="noreferrer"
                      onClick={() => {
                        setIsShowContactUsDrawer(true);
                      }}>{t`联系我们`}</a>
                  </div>
                  <div className={styles.mj_footer_healthGame}>
                    <div>
                      抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
                    </div>
                    <div>
                      COPYRIGHT©2017 上海蛮啾网络科技有限公司{' '}
                      <a href="https://beian.miit.gov.cn/">
                        沪ICP备19021407号-3
                      </a>{' '}
                      沪网文〔2021〕2358-203号{' '}
                      <span style={{ fontFamily: 'SimHei, sans-serif' }}>
                        ©Manjuu Co.,Ltd.
                      </span>
                    </div>
                  </div>
                  <div>
                    <a
                      style={{
                        color: '#BDBDBD',
                      }}
                      target="_blank"
                      href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009828"
                      rel="noreferrer">
                      <Image
                        unoptimized
                        style={{
                          position: 'relative',
                          top: '2px',
                          marginRight: '12px',
                        }}
                        width={14}
                        height={14}
                        alt="公网安备"
                        src={record}
                      />
                      <span>沪公网安备 31010402009828号</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Drawer
        width={528}
        offsetTop={0}
        offsetRight={4}
        isShowMask
        maskOffsetTop={0}
        isShowCloseButton={false}
        clickMaskClosable={true}
        visible={isShowContactUsDrawer}
        onClose={setIsShowContactUsDrawer}>
        <ContactUs setIsShowContactUsDrawer={setIsShowContactUsDrawer} />
      </Drawer>
    </>
  );
};

export default Footer;
