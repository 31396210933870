import React, { useState, useContext, useEffect, useRef } from 'react';
import { useWheelEvent } from '@/hooks/useWheelEvent';
import Image from 'next/image';
import { GlobalContext, PageType } from '@/pages';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper';
import p1_02 from '@/public/images/products/p1/p1_02.png';
import p1_03 from '@/public/images/products/p1/p1_03.png';
import p1_04 from '@/public/images/products/p1/p1_04.png';
import p1_05 from '@/public/images/products/p1/p1_05.png';
import p3_01 from '@/public/images/products/p3/p3_01.jpg';
import p3_02 from '@/public/images/products/p3/p3_02.jpg';
import p3_04 from '@/public/images/products/p3/p3_05.jpg';
import p3_05 from '@/public/images/products/p3/p3_06.jpg';

import slogn1 from '@/public/images/products/p1/slogn.png';
import slogn2 from '@/public/images/products/p3/slogn.jpg';
import { t } from '@lingui/macro';
import styles from './index.module.less';
import ToggleArrows from 'components/ToggleArrows';
// import { dialog } from 'components/Dialog';
import ProductsDetails from 'components/ProductsDetails';
import ProductsDetailsP3 from 'components/ProductsDetailsp3';
import Drawer from 'components/Drawer';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { useIsInternational } from '@/hooks/useIsInternational';

export type ProductsTypes = 'p1' | 'p2' | 'p3';

SwiperCore.use([Autoplay]);

const Products: React.FC<{
  isActive: boolean;
  isNext: boolean;
  isPrev: boolean;
  changeCard: (type: ProductsTypes) => void;

  currentTpl: ProductsTypes;
}> = ({ isActive, isNext, isPrev, currentTpl }) => {
  const [swiperInstanceCard, setSwiperInstanceCard] =
    useState<SwiperClass | null>(null);
  const [swiperInstanceSlogn, setSwiperInstanceSlogn] =
    useState<SwiperClass | null>(null);
  const [swiperInstanceCard2, setSwiperInstanceCard2] =
    useState<SwiperClass | null>(null);
  const [swiperInstanceCard1, setSwiperInstanceCard1] =
    useState<SwiperClass | null>(null);
  const { swiperInstance, setActivePage, activePage } =
    useContext(GlobalContext);
  const [activeProductsType, setActiveProductsType] =
    useState<ProductsTypes>('p1');
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isclick, setIsclick] = useState<boolean>(false);
  const [
    isShowVideoDialog,
    // setIsShowVideoDialog
  ] = useState<boolean>(false);
  const [isShowProductsDetail, setIsShowProductsDetail] =
    useState<boolean>(false);
  const { lang } = useIsInternational();
  useEffect(() => {
    setActiveProductsType(currentTpl);
  }, [currentTpl]);

  useWheelEvent({
    isActive,
    otherHooks: [isShowVideoDialog, isShowProductsDetail],
    upCallback() {
      if (isShowVideoDialog) {
        return;
      }

      if (isShowProductsDetail) {
        setIsShowProductsDetail(false);
        clickToggleArrows();
        return;
      }
      swiperInstance?.slidePrev();
    },
    downCallback() {
      if (isShowVideoDialog) {
        return;
      }
      if (isShowProductsDetail) {
        swiperInstance?.slideNext();
      } else {
        setIsShowProductsDetail(true);
      }
    },
  });

  useEffect(() => {
    if (activePage < PageType.Products) {
      setIsShowProductsDetail(false);
    }
  }, [activePage]);

  const clickNextPage = () => {
    if (!isActive) {
      setActivePage?.(1);
    }
  };

  const productsGroup = {
    p1: [
      {
        url: p1_02,
      },
      {
        url: p1_03,
      },
      {
        url: p1_04,
      },
      {
        url: p1_05,
      },
    ],
    p2: [],
    p3: [
      {
        url: p3_01,
      },
      {
        url: p3_02,
      },

      {
        url: p3_04,
      },
      {
        url: p3_05,
      },
    ],
  };

  const [currentKey, setCurrentKey] = useState('0');
  const swiperIndex1 = useRef(0);
  const swiperIndex2 = useRef(0);
  const addLine1 = (index: number) => {
    setCurrentKey(activeIndex == 0 ? 'p1' + index : 'p3' + index);
  };
  const SwiperConfig: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    onSlideChange(s) {
      setActiveIndex(s.realIndex);

      if (s.realIndex == 1) {
        swiperInstanceCard1?.autoplay?.pause();

        swiperInstanceCard2?.autoplay?.start();
      } else {
        swiperInstanceCard2?.autoplay?.pause();
        swiperInstanceCard1?.autoplay?.start();
      }
    },

    loop: false,
    autoplay: false,
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: 1,
    onSwiper(swiper) {
      setSwiperInstanceCard(swiper);
    },
    onTransitionStart(swiper) {
      addLine1(swiper.activeIndex);
    },
    // dir: 'rtl',
  };

  const SwiperSlognConfig: SwiperProps = {
    speed: 400,
    spaceBetween: 4,
    onSwiper(swiper) {
      setSwiperInstanceSlogn(swiper);
    },

    // onSlideChange(s) {
    //   console.log(s.realIndex, 'onSlideChangeonSlideChange>>>>');

    //   // setActiveSwiper2(s.realIndex);
    // },
    autoplay: false,
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: isShowProductsDetail ? (activeIndex == 1 ? 'auto' : 1) : 2,
    allowTouchMove: false,
  };

  const SwiperConfig2: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    onSwiper(swiper) {
      setSwiperInstanceCard2(swiper);
    },
    onSlideChange() {
      // setActiveSwiper2(s.realIndex);
    },

    onTouchMove(swiper, e) {
      e.stopPropagation();
    },
    onTouchEnd(swiper, e) {
      e.stopPropagation();
    },
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: 1,
    onTransitionStart() {
      if (activeIndex == 1) {
        swiperIndex2.current += 1;
        const realIndex =
          (swiperIndex2.current - 1 + productsGroup['p3'].length) %
          productsGroup['p3'].length;
        addLine1(realIndex);
      }
    },
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    loop: true,
  };
  const SwiperConfig1: SwiperProps = {
    speed: 400,
    spaceBetween: 0,
    onSwiper(swiper) {
      setSwiperInstanceCard1(swiper);
    },

    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    onTouchMove(swiper, e) {
      e.stopPropagation();
    },
    onTouchEnd(swiper, e) {
      e.stopPropagation();
    },

    onTransitionStart() {
      if (activeIndex == 0) {
        swiperIndex1.current += 1;
        const realIndex =
          (swiperIndex1.current - 1 + productsGroup['p3'].length) %
          productsGroup['p1'].length;
        addLine1(realIndex);
      }
    },
    loopedSlides: 1,
    simulateTouch: true,
    slidesPerView: 1,
  };
  const clickToggleArrows = () => {
    setIsShowProductsDetail(!isShowProductsDetail);
    setIsclick(false);
  };

  const next = () => {
    setActiveIndex(Number(!activeIndex));
    setIsclick(true);
  };

  useEffect(() => {
    if (swiperInstanceCard) {
      swiperInstanceCard?.slideTo(activeIndex, 400);
      if (activeIndex == 0) {
        swiperInstanceCard1?.slideTo(0, 400);
        // isShowProductsDetail && swiperInstanceSlogn?.slidePrev();
      } else {
        swiperInstanceCard2?.slideTo(0, 400);
        // isShowProductsDetail && swiperInstanceSlogn?.slideNext();
      }
    }
  }, [swiperInstanceCard, activeIndex]);
  useEffect(() => {
    swiperInstanceSlogn?.slideTo(activeIndex, 400);
  }, [isShowProductsDetail, swiperInstanceSlogn, activeIndex]);

  return (
    <div
      className={classNames([
        styles.mj_products_container,
        !isActive && isNext && styles.mj_products_isNotActive,
      ])}>
      <Swiper className={styles.swiperW100} {...SwiperConfig}>
        <SwiperSlide
          className={classNames([
            styles.swiperW100H,
            styles.mj_products_loop_pic,
            isShowProductsDetail && styles.mj_products_loop_pic_showDetail,
          ])}
          key={'p10'}>
          <Swiper
            key={'p1_card'}
            className={styles.swiperWrapper}
            {...SwiperConfig1}
            loop={activeIndex == 0 ? true : false}>
            {productsGroup['p1']?.map((item, idx) => {
              return (
                <SwiperSlide
                  key={'p1' + 'pic' + idx}
                  className={styles.mj_aboutUs_loop_picSelf}>
                  <Image
                    fill
                    priority
                    src={item.url}
                    alt="Manjuu Pic"
                    crossOrigin="anonymous"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SwiperSlide>
        <SwiperSlide
          className={classNames([
            styles.swiperW100H,
            !isShowProductsDetail && styles.mj_products_loop_pic,
            isShowProductsDetail && styles.mj_products_loop_pic_showDetail,
          ])}
          key={'p3_swiper'}>
          <Swiper
            className={styles.swiperWrapper}
            {...SwiperConfig2}
            loop={activeIndex == 1 ? true : false}
            key={'p3_card'}>
            {productsGroup['p3']?.map((item, idx) => {
              return (
                <SwiperSlide
                  key={'p3' + 'pic' + idx}
                  className={styles.mj_aboutUs_loop_picSelf}>
                  <Image
                    fill
                    priority
                    src={item.url}
                    alt="Manjuu Pic"
                    crossOrigin="anonymous"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SwiperSlide>
      </Swiper>
      {activeIndex == 0 ? (
        <Image
          width={200}
          className={styles.mj_products_Game_Logo}
          src={require(`~/images/products/p1/Logo/AZUR-LANE_${lang}.png`)}
          alt={'AZUR_LANE_Logo'}
        />
      ) : (
        <Image
          width={200}
          className={styles.mj_products_Game_Logo2}
          src={require(`~/images/products/p3/Logo/game_logo_${lang}.png`)}
          alt={'AZUR_LANE_Logo'}
        />
      )}
      <div className={styles.mj_products_swiper_process}>
        {
          <div
            data-id={currentKey}
            key={currentKey}
            id="line1_test"
            className={styles.test_proce}
          />
        }
        {currentKey}
      </div>

      <div
        // onClick={openVideo}
        className={classNames([
          styles.mj_products_video,
          (isActive || isPrev) && styles.mj_products_video_show,
        ])}>
        <div className={styles.mj_products_video_playBtn}>
          <div className={styles.mj_products_triangle} />
        </div>
        <div
          className={classNames([
            styles.mj_products_flex,
            isShowProductsDetail && activeIndex == 0 && styles.mj_products_p1,
            isShowProductsDetail && activeIndex == 1 && styles.mj_products_p3,
          ])}>
          <div className={styles.all_banner}>
            {' '}
            <Swiper
              className={classNames([styles.banner_all])}
              {...SwiperSlognConfig}>
              <SwiperSlide
                key={'ax1'}
                onClick={() => {
                  setActiveIndex(0);
                }}
                style={{
                  width: `${
                    isShowProductsDetail
                      ? activeIndex == 1 && !isclick
                        ? 0
                        : 100 + '%'
                      : 50 + '%'
                  }`,
                  transition: '0.6s',
                }}
                className={classNames([
                  styles.banner_two,
                  isShowProductsDetail && styles.banner_one_show,
                  styles.mj_products_flex_card,
                  activeIndex == 0 && styles.mj_products_mask,
                ])}>
                {/* <div
                  className={styles.all_banner_img1}
                  style={{ backgroundImage: `url(${slogn1.src})` }}></div> */}
                <Image
                  fill
                  priority
                  src={slogn1}
                  alt="Manjuu Pic"
                  crossOrigin="anonymous"
                />
                {!isShowProductsDetail && (
                  <span className={styles.title}>{t`碧蓝航线`}</span>
                )}
              </SwiperSlide>
              <SwiperSlide
                key={'ax2'}
                className={classNames([
                  styles.mj_products_flex_card,
                  activeIndex == 1 && styles.mj_products_mask,
                  ,
                ])}
                onClick={() => {
                  setActiveIndex(1);
                }}
                style={{
                  width: `${
                    isShowProductsDetail
                      ? activeIndex == 0 && !isclick
                        ? 0
                        : 100 + '%'
                      : 50 + '%'
                  }`,
                  transition: '0.6s',
                }}>
                <Image
                  className={styles.all_banner_img2}
                  fill
                  priority
                  src={slogn2}
                  alt="Manjuu Pic"
                  crossOrigin="anonymous"
                />
                {!isShowProductsDetail && (
                  <span className={styles.title}>{t`蓝色星原：旅谣`}</span>
                )}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      <div
        style={{
          right: isShowProductsDetail ? '552px' : '28px',
          zIndex: isShowProductsDetail ? 9999 : 2,
        }}
        onClick={clickToggleArrows}
        className={styles.mj_products_detail_toggle}>
        <ToggleArrows isToggle={isShowProductsDetail} />
      </div>

      <Drawer
        width={528}
        isShowMask={false}
        clickMaskClosable={false}
        isShowCloseButton={false}
        visible={isShowProductsDetail}
        onClose={setIsShowProductsDetail}>
        <>
          {isShowProductsDetail && (
            <div className={styles.mj_products_card_btn}>
              <span onClick={next}>
                {' '}
                <Icon className={styles.left_arrow} fontClass="jiantou-41" />
              </span>
              <span onClick={next}>
                <Icon className={styles.right_arrow} fontClass="jiantou-41" />
              </span>
            </div>
          )}
          {activeIndex == 0 ? (
            <ProductsDetails
              activeProductsType={activeProductsType}
              setActiveProductsType={setActiveProductsType}
            />
          ) : (
            <ProductsDetailsP3
              activeProductsType={activeProductsType}
              setActiveProductsType={setActiveProductsType}
            />
          )}
        </>
      </Drawer>

      <div
        onClick={clickNextPage}
        className={classNames([
          styles.mj_homePage_scrollTips,
          isActive
            ? styles.mj_homePage_scrollTips_hidden
            : isPrev && !isNext && styles.mj_homePage_scrollTips_hidden,
        ])}>
        <Icon
          className={styles.mj_homePage_scrollTips_svg}
          fontClass="ICON-39"
        />
        ️
      </div>
    </div>
  );
};

export default Products;
