import React, { useEffect, useState } from 'react';
import style from './index.module.less';
import Products, { ProductsTypes } from '../Products';
import { PageType } from '@/pages';

const ProductCard: React.FC<{
  isActive: boolean;
  isNext: boolean;
  isPrev: boolean;
  activeIndex: PageType;
}> = props => {
  const { isActive, isPrev, isNext, activeIndex } = props;
  const [currentTpl, setCurrentTpl] = useState<ProductsTypes>('p1');
  const changeCard = (type: ProductsTypes) => {
    setCurrentTpl(type);
  };
  useEffect(() => {
    if (activeIndex !== PageType.Products) {
      setCurrentTpl('p1');
    }
  }, [activeIndex]);
  return (
    <>
      <div className={style.productCar}>
        <Products
          currentTpl={currentTpl}
          isNext={isNext}
          isPrev={isPrev}
          isActive={isActive}
          changeCard={changeCard}></Products>
      </div>
    </>
  );
};
export default ProductCard;
