import React from 'react';
import styles from './index.module.less';

type ToggleArrowsType = {
  isToggle: boolean;
  color?: string;
  style?: React.CSSProperties;
};

const ToggleArrows: React.FC<ToggleArrowsType> = ({
  isToggle,
  color = '#FFF',
  style,
}) => {
  return (
    <span className={styles.toggleArrowsBody} style={style}>
      <div
        style={{
          transform: `rotate(${isToggle ? 60 : 120}deg)`,
          background: color,
        }}
        className={styles.Left}
      />
      <div
        style={{
          transform: `rotate(${isToggle ? 120 : 60}deg)`,
          background: color,
        }}
        className={styles.Right}
      />
    </span>
  );
};

export default ToggleArrows;
