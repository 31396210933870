import type { NextPage } from 'next';
import { GetStaticProps } from 'next';
import styles from './index.module.less';
import { loadTranslation, parseQuery } from '@/libs';
import Header from 'components/Header';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper';
import HomePage from 'components/HomePage';
import AboutUs from 'components/AboutUs';
import News from 'components/News';
import { useReplaceState } from '@/hooks/useReplaceState';
import { useInnerHeight } from '@/hooks/useInnerHeight';
import SwiperCore, { Mousewheel } from 'swiper';
import Image from 'next/image';
import { useIsInternational } from '@/hooks/useIsInternational';
import Cookies from 'js-cookie';
import ProductCard from '@/components/ProductCard';

export const bgc_img = require('@/public/images/page_background.png');

SwiperCore.use([Mousewheel]);

export enum PageType {
  HomePage,
  Products,
  AboutUs,
  JoinUs,
  NEWS,
  Others,
}

export const getStaticProps: GetStaticProps = async ctx => {
  const translation = await loadTranslation(
    ctx.locale!,
    process.env.NODE_ENV === 'production'
  );
  return {
    props: {
      translation,
    },
  };
};

export const GlobalContext = createContext<{
  swiperInstance: SwiperClass | null;
  activePage: number;
  setActivePage?: React.Dispatch<number>;
}>({
  swiperInstance: null,
  activePage: 0,
});

const Home: NextPage = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null
  );
  const [activePage, setActivePage] = useState<PageType>(PageType.HomePage);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isLang, lang } = useIsInternational();

  const { replaceState } = useReplaceState();

  useEffect(() => {
    if (!Cookies.get('lang')) {
      Cookies.set('lang', lang, { expires: 30 });
    }
  }, [lang]);

  const getScrollbarSize = () => {
    const container = document.createElement('div');
    document.body.appendChild(container);
    container.style.overflow = 'scroll';

    // Add ad fake div inside the container
    const inner = document.createElement('div');
    container.appendChild(inner);

    const height = container.offsetHeight - inner.offsetHeight;
    document.body.removeChild(container);
    document.documentElement.style.setProperty(
      '--scrollbar',
      `${global.document.body.offsetWidth <= 1200 ? height : 0}px`
    );
  };

  useInnerHeight();

  useEffect(() => {
    const timeId = setTimeout(() => {
      const type = parseQuery(global.location.search)?.page;
      if (type && type !== 'HomePage') {
        // @ts-ignore
        setActivePage(PageType[type]);
      }
    }, 500);
    getScrollbarSize();
    global.addEventListener('resize', () => {
      getScrollbarSize();
    });
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  useEffect(() => {
    if (swiperInstance) {
      if (PageType[activePage]) {
        replaceState({ page: PageType[activePage] });
      }
      if (activePage !== PageType.JoinUs) {
        swiperInstance?.slideTo(activePage, 600);
      } else {
        swiperInstance?.slideTo(PageType.AboutUs, 600);
      }
    }
  }, [activePage, swiperInstance]);

  const onSlideChange = (swiperEvent: SwiperClass) => {
    const { activeIndex } = swiperEvent;
    if (activeIndex === PageType.JoinUs) {
      setActivePage(PageType.NEWS);
      return;
    }
    if (activeIndex === PageType.AboutUs && activePage === PageType.JoinUs) {
      setActivePage(PageType.JoinUs);
      return;
    }
    if (activePage !== PageType.NEWS) {
      setActivePage(activeIndex);
    }
  };

  const SwiperConfig: SwiperProps = {
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 600,
    mousewheel: {
      sensitivity: 1000,
      thresholdDelta: 2000,
      thresholdTime: 2000,
    },
    direction: 'vertical',
    simulateTouch: false,
    onSlideChange,
    onSwiper(swiper) {
      setSwiperInstance(swiper);
    },
  };

  return (
    <GlobalContext.Provider
      value={{ swiperInstance, activePage, setActivePage }}>
      <div ref={containerRef} className={styles.mj_container}>
        <Image
          className={styles.mj_container_bgc}
          fill
          src={bgc_img}
          alt="pic"
        />
        <Header />
        <Swiper
          resistanceRatio={0}
          {...SwiperConfig}
          className={styles.swiper_container}>
          <SwiperSlide>
            {({ isActive }) => <HomePage isActive={isActive}></HomePage>}
          </SwiperSlide>
          <SwiperSlide>
            {({ isActive, isNext, isPrev }) => (
              <ProductCard
                activeIndex={activePage}
                isActive={isActive}
                isNext={isNext}
                isPrev={isPrev}></ProductCard>
            )}
          </SwiperSlide>
          <SwiperSlide>
            {({ isActive }) => <AboutUs isActive={isActive} />}
          </SwiperSlide>
          {isLang('zh') && (
            <SwiperSlide>
              {({ isActive }) => <News isActive={isActive}></News>}
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </GlobalContext.Provider>
  );
};

export default Home;
